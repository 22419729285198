@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.bootstrap-select {
    height: 2rem !important;
    .select__control {
        background-color: transparent !important;
        border: 0.0625rem solid $text-high-emphasis !important;
        border-radius: 0.25rem !important;
        box-shadow: none !important;
        color: $text-high-emphasis;
        min-height: 2rem !important;
        height: 2rem !important;
        flex-wrap: nowrap !important;
    }

    .select__input-container {
        color: $text-high-emphasis;
        height: 1.375rem !important;
        margin: 0rem !important;
    }
    .select__placeholder {
        height: 100% !important;
    }
    .select__menu {
        background-color: $body-color !important;
        border: 0.0625rem solid $text-high-emphasis;
        border-radius: 0.25rem;
        color: $text-high-emphasis;
        cursor: pointer;
        z-index: 1;
        min-width: 5rem !important;
    }

    .select__value-container {
        color: $text-high-emphasis;
        display: flex;
        flex-wrap: wrap;
        max-width: 62.5rem;
        overflow-y: auto;
        max-height: 1.5rem !important;
        padding-top: 0rem !important;
        gap: 0.5rem;
        @extend .custom-scrollbar;
    }

    .select__option {
        padding: 0.625rem;
        &:hover {
            background-color: $primary-500 !important;
            color: $body-color;
        }
    }
    .select__clear-indicator {
        display: none !important;
    }
    .select__indicator-separator {
        display: none;
    }

    .select__option--is-selected {
        background-color: transparent !important;
        color: $body-color;
    }

    .select__option--is-focused {
        background-color: transparent !important;
    }

    .select__multi-value {
        cursor: pointer;
        background-color: $vizualization-blue-03 !important;
        border-radius: 1.75rem;
        border: 0.03125rem solid $vizualization-blue-02 !important;
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 0.25rem 0.5rem;
        height: 1.3rem !important;
        margin: 0rem !important;
    }
    .select__multi-value__remove {
        cursor: pointer;
        background-color: $vizualization-blue-03 !important;
        color: $text-high-emphasis !important;
        border-radius: 4rem;
    }

    .select__multi-value__label {
        color: $text-high-emphasis;
        height: 1.2rem;
        display: flex;
        align-items: center;
    }

    &__multi-value-label {
        width: 1rem;
        height: 1rem;
        margin-right: 0.3125rem;
    }
    &__custom-option {
        display: flex;
        align-items: center;
        padding: 0.625rem;
    }
}
