.dept-card {
    height: 8.59375rem;
    min-width: 85.9375rem;

    .vr {
        min-width: 0.5px;
    }

    .body8 {
        margin-bottom: 0rem;
    }

    &__typography-content {
        width: max-content;
    }

    .subtitle3 {
        margin-bottom: 0rem;
    }

    .subtitle5 {
        margin-bottom: 0rem;
    }
}

.card-content {
    &__expand-icon {
        cursor: pointer;
    }
}
